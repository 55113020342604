$track-w: auto;
$track-h: 2px;
$thumb-d: 20px;
$track-c: #D9D9D9;
$filll-c: #EE2323;
$thumb-c: #EE2323;

@mixin track($fill: 0) {
  box-sizing: border-box;
  border: none;
  height: $track-h;
  background: $track-c;

  @if $fill == 1 {
			background: linear-gradient($filll-c, $filll-c)
				0/ var(--sx) 100% no-repeat $track-c
	}
}

@mixin fill() {
  height: $track-h;
  background: $filll-c
}

@mixin thumb() {
  box-sizing: border-box;
  border: 2px solid white;
  width: $thumb-d; height: $thumb-d;
  border-radius: 50%;
  background: $thumb-c;
  cursor: pointer;
  box-shadow: none;
}

[type='range'] {
  &, &::-webkit-slider-thumb {
    -webkit-appearance: none;
  }
  
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--val) - var(--min))/var(--range));
  --sx: calc(.5*#{$thumb-d} + var(--ratio)*(100% - #{$thumb-d}));
  margin: 0;
  padding: 0;
  width: $track-w; height: $thumb-d;
  background: transparent;
  font: 1em/1 arial, sans-serif;
  
  &::-webkit-slider-runnable-track {
    @include track(1)
  }
  &::-moz-range-track { @include track }
  &::-ms-track { @include track }
  
  &::-moz-range-progress { @include fill }
  &::-ms-fill-lower { @include fill }
  
  &::-webkit-slider-thumb {
    margin-top: .5*($track-h - $thumb-d);
    @include thumb
  }
  &::-moz-range-thumb { @include thumb }
  &::-ms-thumb {
    margin-top: 0;
    @include thumb
  }
  
  &::-ms-tooltip { display: none }
}